$list-margin-bottom: 1em;

// специальная среда для текстовых html блоков которые приходят с бекенда

@import "variables";
@import "mixins";

.prose {
    font-family: $base-font-size;
    color: var(--foreground);
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }

    h1 {
        @include heading-1();
    }

    h2 {
        @include heading-2();
    }

    h3 {
        @include heading-3();
    }

    h4 {
        @include heading-4();
    }

    p {
        margin-bottom: 1em;
    }

    a {
        color: var(--primary);
        text-decoration: underline;

        &:hover {
            color: var(--primary-accent);
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    blockquote {
        border-left: 4px solid var(--field-border);
        padding-left: 1.5em;
        margin: 1.5em 0;
        color: var(--field-foreground);
        font-style: italic;

        p {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        list-style: unset;
        margin-bottom: $list-margin-bottom;
        padding-left: 1.5em;

        li {
            margin-bottom: 0.5em;

            &::marker {
                color: var(--foreground);
            }

            p {
                margin-bottom: 0.5em;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1.5em;

        th,
        td {
            border: 1px solid var(--field-border);
            padding: 0.75em;
            text-align: left;
        }

        th {
            // background-color: lighten(var(--field-border), 30%);
            font-weight: bold;
        }

        // tbody tr:nth-child(even) {
        //     background-color: lighten(var(--field-border), 50%);
        // }
    }

    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1.5em;
    }

    pre {
        background-color: #f5f5f5;
        padding: 1em;
        border-radius: 5px;
        margin-bottom: 1.5em;
        overflow-x: auto;
    }

    code {
        background-color: #f5f5f5;
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-family: "Courier New", monospace;
    }

    hr {
        border: 0;
        border-top: 1px solid var(--field-border);
        margin: 2em 0;
    }
}
