@import "@packages/styles/mixins";

.breadcrumb_list {
    margin: 8px 0;
    @include text-breadcrumbs();
    color: var(--muted-foreground);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;

    & li:not(:last-child)::after {
        content: "/";
        margin: 0 8px;
    }

    & li:hover > * {
        text-decoration: underline;
    }

    & li:last-child {
        color: var(--foreground);
    }
}
