@import "@packages/styles/mixins";
@import "@packages/styles/variables";

.SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    min-width: 200px;
    height: 20px;
}

.SliderRoot::before,
.SliderRoot::after {
    content: "";
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: transparent;
    border: 2px solid var(--slider);
}

.SliderTrack {
    background-color: var(--slider);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 4px;
}

.SliderRange {
    position: absolute;
    background-color: var(--slider-foreground);
    border-radius: 9999px;
    height: 100%;
}

.SliderThumb {
    z-index: 5;
    position: relative;
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--card);
    border: 2px solid var(--slider-foreground);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.SliderLabel {
    display: flex;
    z-index: 0;
    border: 1.5px solid var(--slider);
    position: absolute;
    height: 12px;
    transform: translateX(-50%);
}

.SliderLabel::after {
    width: max-content;
    display: flex;
    content: attr(data-label);
    transform: translateX(-50%);
    color: var(--muted-foreground);
}

.SliderLabel::after,
.CurrentValue {
    width: max-content;
    @include text-breadcrumbs();
    background: var(--card);
    border-radius: $radius-m;
    padding: 2px 4px;
    position: absolute;
    top: 140%;
}

.min,
.max {
    @include text-breadcrumbs();
    top: 16px;
    position: absolute;
}

.min {
    color: var(--muted-foreground);
    left: 0;
    // transform: translateX(-50%);
}

.max {
    color: var(--foreground);
    right: 0;
    // transform: translateX(50%);
}

.SliderThumb:focus {
    outline: none;
}
