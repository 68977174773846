@import "@packages/styles/variables";
@import "@packages/styles/mixins";

.toastViewport {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    max-height: 100vh;
    width: 100%;
    bottom: auto;
    right: 0;
    top: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    max-width: 420px;
}

.toast {
    @include text-footnote();
    position: relative;
    background: var(--card);
    pointer-events: auto;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;
    overflow: hidden;
    border-radius: $radius-l;
    padding: 28px 38px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &[data-state="open"] {
        animation: in-animation 300ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state="closed"] {
        animation: hide 100ms ease-in;
    }

    &[data-swipe="move"] {
        transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe="cancel"] {
        transform: translateX(0);
        transition: transform 200ms ease-out;
    }
}

.toastAction {
    display: inline-flex;
    height: 2rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border: 1px solid;
    background-color: transparent;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition:
        background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: var(--secondary-bg-color);
    }

    &:focus {
        outline: none;
        box-shadow:
            0 0 0 2px var(--ring-color),
            0 0 0 4px var(--ring-offset-color);
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.toastTitle {
    opacity: 1;
}

.toast_varinat_success .toastTitle {
    color: var(--success-foreground);
}

.toast_varinat_error .toastTitle {
    color: var(--error-foreground);
}

.toastDescription {
    opacity: 1;
}

.toastClose {
    position: absolute;
    top: 28px;
    right: 28px;
    color: var(--muted);
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
        transform: translateX(calc(100% + var(--radix-toast-swipe-move-x)));
    }
}

@keyframes in-animation {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes out-animation {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}
