@import "@packages/styles/breakpoints";
@import "@packages/styles/mixins";

.card {
    @include shadow-regular();
    width: 960px;
    padding-left: 64px;
    padding-right: 64px;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    & p {
        @include text-footnote();
        max-width: 550px;
    }

    & button {
        width: 230px;
    }

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }
}
