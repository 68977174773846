@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes out-animation {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.2;
    }
}

@keyframes overlayHide {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 0;
    }
}
