@import "@packages/styles/breakpoints";

.base_layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;

    main {
        min-height: 70vh;
        flex: 1 0 auto;
        margin-top: 24px;
        margin-bottom: 56px;
    }

    footer {
        flex: 0 0 auto;
    }
}
