// //Типографика
// $font-size-xs: 12px;
// $font-size-s: 14px;
// $font-size-base: 16px;
// $font-size-l: 18px;
// $font-size-xl: 24px;
// $font-size-2xl: 32px;

// $line-height-s: 22px;
// $line-height-m: 24px;
// $line-height-l: 28px;

// Типографика в rem
$base-font-size: 16px;

$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-base: 1rem;
$font-size-l: 1.125rem;
$font-size-xl: 1.5rem;
$font-size-2xl: 2rem;

$line-height-s: 1.375rem;
$line-height-m: 1.5rem;
$line-height-l: 1.75rem;

//Радиусы
$radius-xs: 5px;
$radius-s: 9px;
$radius-m: 11px;
$radius-l: 15px;
