.loader_variant_spin {
    border: 2px solid var(--muted-foreground);
    border-left-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loader_theme_white {
    border-color: #fff;
    border-left-color: transparent;
}

.loader_theme_primary {
    border-color: var(--primary);
    border-left-color: transparent;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
