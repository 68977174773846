@import "@packages/styles/variables";

.fallback_wrapper {
    padding: 150px 24px;
    background: var(--card);
    color: var(--card-foreground);
    border-radius: $radius-l;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    a {
        color: var(--primary);
    }
}
