@import "palette";
@import "breakpoints";
@import "themes/light";
@import "themes/dark";
@import "themes/fng-theme";
@import "themes/smartfox-theme";
@import "variables";
@import "mixins";
@import "utils";
@import "typhography";
@import "animations";

* {
    padding: 0;
    margin: 0;
    scroll-margin-top: 7em;
}

html,
body {
    box-sizing: border-box;
    position: relative;
    scroll-behavior: smooth;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

button,
fieldset,
input {
    all: unset;
}

body {
    @include text-regular();
    background: var(--background);
    color: var(--foreground);
    font-family: "Manrope Variable", sans-serif;
    height: 100%;
    transition: background-color 1s ease;
}

hr {
    border: none;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
}

h1 {
    @include heading-1();
}
h2 {
    @include heading-2();
}
h3 {
    @include heading-3();
}
h4 {
    @include heading-4();
    margin-bottom: 8px;
}
