@import "@packages/styles/mixins";

.btn {
    @include text-button();
    cursor: pointer;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    &_theme_outline {
        background: transparent;
        color: var(--outline-foreground);
        border: 1px solid var(--outline-border);
    }

    &_theme_outline:hover,
    &_theme_muted:hover {
        background: var(--primary);
        color: var(--primary-foreground);
        .loader {
            border-color: #fff;
            border-left-color: transparent;
        }
    }

    &_theme_primary {
        background: var(--primary);
        color: var(--primary-foreground);
        border: 1px solid var(--primary-accent);
    }

    &_theme_primary:hover {
        background: var(--primary-accent);
    }

    &_theme_text {
        padding: 0;
        background: transparent;
        color: var(--foreground-accent);
    }

    &_theme_muted {
        background: var(--background);
        color: var(--foreground-accent);
    }

    &_theme_pale {
        @include text-footnote();
        background: var(--pale);
        color: var(--pale-foreground);

        &:hover {
            background: var(--pale-accent);
        }
    }

    &_theme_disabled {
        background: var(--disabled);
        color: var(--disabled-foreground);
        outline: 1px solid var(--disabled-accent);
        pointer-events: none;
        cursor: default;
    }

    &_size_default {
        padding: 18px;
        border-radius: $radius-m;
    }

    &_size_small {
        padding: 8px;
        border-radius: $radius-s;
    }

    &_size_min {
        padding: 0;
        border-radius: 0;
    }

    &_width_stretch {
        box-sizing: border-box;
        width: 100%;
    }
}
