.CheckboxRoot {
    cursor: pointer;
    background-color: var(--card);
    aspect-ratio: 1/1;
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--field-border);
}

.CheckboxRoot:hover {
    border-color: var(--primary);
}

.CheckboxRoot[data-state="checked"] {
    background-color: var(--primary);
}

.CheckboxIndicator {
    color: white;
}
