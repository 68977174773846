.RadioGroupRoot {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.RadioGroupItem {
    background-color: transparent;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--foreground);
}
[data-state="checked"],
.RadioGroupItem:focus {
    border-color: var(--primary);
}

.RadioGroupIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.RadioGroupIndicator::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary);
}
