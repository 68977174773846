@import "@packages/styles/variables";
@import "@packages/styles/mixins";
@import "@packages/styles/palette";

.overlay {
    z-index: 10;
    position: fixed;
    inset: 0;
    background: black;
    opacity: 0.25;

    &[data-state="open"] {
        animation: overlayShow 100ms ease-out;
    }

    &[data-state="closed"] {
        animation: overlayHide 100ms ease-in;
    }
}

.content {
    z-index: 15;
    background-color: var(--card);
    color: var(--card-foreground);
    border-radius: $radius-m;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    // max-height: 85vh;
    padding: 32px 64px;

    &[data-state="open"] {
        animation: contentShow 100ms ease-in;
    }

    &[data-state="closed"] {
        animation: out-animation 100ms ease-out;
    }
}

.content:focus {
    outline: none;
}

// .description {
//     margin: 10px 0 20px;
//     color: var(--mauve-11);
//     font-size: 15px;
//     line-height: 1.5;
// }

.title {
    @include heading-2();
    margin-bottom: 32px;
}

.description {
    @include text-form-label();
    opacity: 1;
    margin-bottom: 16px;
}

.trigger {
    cursor: pointer;
}

.close {
    color: var(--muted);
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.2;
    }
}

@keyframes overlayHide {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 0;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes out-animation {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
}
