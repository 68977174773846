@import "@packages/styles/mixins";

.chip {
    @include text-footnote();
    display: flex;
    gap: 3px;
    align-items: center;

    & {
        max-width: 100px;
    }

    & button {
        cursor: pointer;
    }
}
