.light-theme {
    --background: #{$deep-white};
    --foreground: #{$dark-gray};
    --foreground-accent: #{$blue};

    --pale: #{$deep-white};
    --pale-foreground: #{$dark-gray};
    --pale-accent: #{$light-blue};

    --accent: #{$light-blue};

    --primary: #{$blue};
    --primary-foreground: #{$white};
    --primary-accent: #{$edge-blue};

    --secondary: #{$light-blue};
    --secondary-foreground: #{$blue};
    --secondary-accent: #{$edge-light-blue};

    --card: #{$white};
    --card-foreground: #{$dark-gray};
    --card-border: #{$super-pale-gray};

    --muted: #{$edge-gray};
    --muted-foreground: #{$gray};

    --field: #{$white};
    --field-foreground: #{$dark-gray};
    --field-border: #{$edge-gray};
    --field-accent: #{$blue};

    --promo: #{$pink};
    --promo-foreground: #{$white};

    --error-foreground: #{$pink};

    --success-foreground: #{$green};

    --slider: #{$light-blue};
    --slider-foreground: #{$edge-blue};

    --disabled: #{$deep-white};
    --disabled-foreground: #{$gray};
    --disabled-accent: #{$edge-gray};
}
