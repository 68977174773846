.hexagon {
    background: var(--hexagon);
    color: var(--hexagon-foreground);
    position: relative;
    width: 200px;
    height: 200px;
    // background: transparent;
    overflow: hidden;

    .shape {
        stroke: var(--hexagon-border);
        stroke-width: 1;
    }

    &_active {
        background: var(--hexagon-accent);
        .shape {
            stroke: var(--primary);
            stroke-width: 1;
            filter: drop-shadow(0px 10px 10px rgba(24, 144, 255, 0.1));
        }
    }
}

.hexagon > .hexagon_inner img {
    object-fit: contain;
    max-width: 100%;
}

.hexagon > .hexagon_inner {
    object-fit: cover;
    position: absolute;
    width: 88%;
    height: 100%;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
