.counter {
    display: block;
    border-radius: 100px;
    background: var(--primary-accent);
    color: var(--card);
    padding: 2px 8px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    // line-height: 22px;
}
