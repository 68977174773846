.popover {
    z-index: 50;
    width: 18rem;
    border-radius: 0.375rem;
    background-color: var(--card);
    padding: 1rem;
    color: var(--card-foreground);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;

    &[data-state="open"] {
        animation:
            fade-in 0.15s ease-in-out,
            zoom-in 0.15s ease-in-out;
    }

    &[data-state="closed"] {
        animation:
            fade-out 0.15s ease-in-out,
            zoom-out 0.15s ease-in-out;
    }

    &[data-side="bottom"] {
        animation: slide-in-from-top 0.15s ease-in-out;
    }

    &[data-side="top"] {
        animation: slide-in-from-bottom 0.15s ease-in-out;
    }

    &[data-side="left"] {
        animation: slide-in-from-right 0.15s ease-in-out;
    }

    &[data-side="right"] {
        animation: slide-in-from-left 0.15s ease-in-out;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes zoom-in {
    from {
        transform: scale(0.95);
    }
    to {
        transform: scale(1);
    }
}

@keyframes zoom-out {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.95);
    }
}

@keyframes slide-in-from-top {
    from {
        transform: translateY(-0.5rem); // slide-in-from-top-2 = 8px
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-in-from-bottom {
    from {
        transform: translateY(0.5rem); // slide-in-from-bottom-2 = 8px
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-in-from-left {
    from {
        transform: translateX(-0.5rem); // slide-in-from-left-2 = 8px
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-in-from-right {
    from {
        transform: translateX(0.5rem); // slide-in-from-right-2 = 8px
    }
    to {
        transform: translateX(0);
    }
}
