$gray: #8c8c8c;
$dark-gray: #414544;
$edge-gray: #d9d9d9;
$super-pale-gray: #0000000f;

$deep-black: #232323;

$blue: #1890ff;
$light-blue: #e6f7ff;
$edge-blue: #047cec;
$edge-light-blue: #c0ebff;
$deep-blue: #00518b;

$green: #00880e;

$white: #fff;
$deep-white: #f0f2f5;

$pink: #ff0f57;

$orange: #df733a;
$dark-gold: #7e5a28;

// Дополнительные акцентные оттенки
$teal: #1abc9c; // Яркий бирюзовый (teal)
$deep-teal: #0e6655; // Глубокий темный бирюзовый
$purple: #9b59b6; // Яркий фиолетовый
$deep-purple: #5d3d73; // Темный глубокий фиолетовый

// Дополнительные оттенки для темной темы
$dark-bg: #121212; // Очень темный фон
$dark-card: #1f1f1f; // Темно-серый фон для карточек
$dark-muted: #2a2a2a; // Для muted элементов
