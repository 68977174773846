@import "./breakpoints";

.container {
    width: 100%;
    box-sizing: border-box;
    padding-left: 48px;
    padding-right: 48px;
    max-width: $xxl;
    margin: 0 auto;
}

.freeze {
    opacity: 0.5;
    // filter: grayscale(1);
    pointer-events: none;
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.theme-transition {
    & * {
        transition:
            background-color 0.5s ease,
            color 0.5s ease-in-out;
    }
}

// прячет скролбар
.no_scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
