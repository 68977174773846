@import "@packages/styles/variables";
@import "@packages/styles/mixins";

.autocomplete_wrapper {
    box-sizing: border-box;
    display: flex;
    @include text-input();
    position: relative;
    min-height: 57px;
    z-index: 4;
}

.autocomplete_wrapper > div {
    inset: 0;
    position: absolute;
    height: max-content;
}

.autocomplete {
    box-sizing: border-box;
    border: 1px solid var(--field-border);
    border-radius: $radius-m;
    background: var(--field);

    &_input {
        outline: none;
    }

    &_options {
        padding: 24px 16px;
        padding-top: 0;
    }

    &_feature {
        cursor: default;
        padding: 4px 8px;
    }

    [cmdk-item] {
        cursor: pointer;
        padding: 4px 8px;
        border-radius: $radius-xs;
    }

    [data-selected="true"] {
        background: var(--secondary);
    }

    [cmdk-list] {
        box-sizing: border-box;
        width: 100%;
        border-radius: 0 0 $radius-m $radius-m;
        background: var(--field);
        height: calc(var(--cmdk-list-height) + 24px);
        transition: height 300ms ease;
        overflow-y: auto;
    }

    .toggler {
        margin-left: 8px;
        transform-origin: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: rotate 0.1s linear;

        &[data-state="true"] {
            rotate: (-90deg);
        }

        &[data-state="false"] {
            color: var(--primary);
            rotate: 90deg;
        }
    }
}
