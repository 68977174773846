.smartfox-theme {
    --background: #{$deep-white};
    --foreground: #{$dark-gray};
    --foreground-accent: #6585cf;

    --pale: #{$deep-white};
    --pale-foreground: #{$dark-gray};
    --pale-accent: #ececec;

    --accent: #{$light-blue};

    --primary: #{$orange};
    --primary-foreground: #{$white};
    --primary-accent: #f15218;

    --secondary: #f0f2f5;
    --secondary-foreground: #6585cf;
    --secondary-accent: transparent;

    --outline: #{$white};
    --outline-foreground: #6585cf;
    --outline-border: transparent;

    --card: #{$white};
    --card-foreground: #{$dark-gray};
    --card-border: #{$super-pale-gray};

    --muted: #{$edge-gray};
    --muted-foreground: #{$gray};

    --field: #{$white};
    --field-foreground: #{$dark-gray};
    --field-border: #{$edge-gray};
    --field-accent: #414544;

    --header-foreground-accent: #{$edge-light-blue};

    --hexagon: transparent;
    --hexagon-foreground: #{$dark-gray};
    --hexagon-border: #{$edge-gray};
    --hexagon-accent: radial-gradient(
        49.82% 40.17% at 49.92% 50%,
        #c3c3c3 0%,
        #ffffff 100%
    );

    --promo: #{$pink};
    --promo-foreground: #{$white};

    --error-foreground: #{$pink};

    --success-foreground: #{$green};

    --slider: #dbdbdb;
    --slider-foreground: #df733a;

    --disabled: #{$deep-white};
    --disabled-foreground: #{$gray};
    --disabled-accent: #{$edge-gray};
}
