@import "@packages/styles/mixins";

.RadioCardGroupRoot {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.RadioCardGroupItem {
    @include text-footnote();
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.2s ease;
    background-color: var(--card);
    color: var(--card-foreground);
    border: 1px solid var(--muted);

    p {
        @include text-breadcrumbs();
        color: var(--muted-foreground);
    }

    &:hover {
        border-color: var(--secondary-accent);
    }

    &[data-state="checked"] {
        border-color: var(--secondary-accent);
        background: var(--secondary);
    }
}

.RadioCardGroupContent {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
