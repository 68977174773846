@import "@packages/styles/mixins";
@import "@packages/styles/variables";

.input:focus-within {
    outline-color: var(--field-accent);
}

.input {
    @include text-input();
    position: relative;
    outline: 1px solid var(--field-border);
    border-radius: $radius-m;
    color: var(--field-foreground);
    background: var(--field);
    display: flex;
    // height: max-content;

    label {
        position: absolute;
        left: 0;
        top: 50%;
        margin-left: 16px;
        transform: translateY(-50%);
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
        border-radius: $radius-xs;
        padding: 0 8px;
        background: var(--field);
    }

    input {
        color: var(--field-foreground);
        width: 100%;
        padding: 18px 24px;
        border-radius: $radius-m;
        outline: none;
        border: none;
    }

    input:focus + label {
        color: var(--muted-foreground);
        top: 5px;
        transform: translateY(-50%) scale(0.8);
    }

    input:not(:placeholder-shown) + label {
        color: var(--muted-foreground);
        top: 5px;
        transform: translateY(-50%) scale(0.8);
    }

    input:not(:focus)::placeholder {
        opacity: 0;
    }

    input.required + label::after {
        content: " *";
        color: var(--error-foreground);
    }
    /* Убираем крестик в Chrome, Safari, Edge */
    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }

    /* Убираем крестик в Firefox */
    input[type="search"]::-moz-search-cancel-button {
        -moz-appearance: none;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Скрываем стандартные стрелки на Firefox */
    input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
    }

    .icon {
        width: max-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 16px;
        min-width: 16px;
    }

    .reset {
        width: max-content;
        color: var(--muted);
    }

    .action_bar {
        justify-content: end;
        display: flex;
        gap: 4px;
        justify-content: end;
        align-items: center;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
    }

    &_variant_error {
        color: var(--error-foreground);
        outline-color: var(--error-foreground);
        &:focus-within {
            outline-color: var(--error-foreground);
        }
    }

    &_variant_search {
        input:not(:focus)::placeholder {
            opacity: 1;
        }
    }
}

.helper_text {
    position: absolute;
    @include text-breadcrumbs();
    text-align: start;
    left: 24px;
    top: 100%;
}

.number_input {
    justify-content: center;
    background: var(--pale);
    border-radius: $radius-s;
    overflow: hidden;
    width: 100%;
    display: flex;
    padding: 2px;
    gap: 2px;

    & .blocked_crease_button {
        pointer-events: none;
        color: var(--muted-foreground);
    }

    & > button {
        width: 100%;
        color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        padding: 16px;

        &:hover {
            background: var(--primary);
            border-radius: 7px;
            color: var(--primary-foreground);
        }
    }

    & > div {
        @include heading-5();
        min-width: 70px;
        width: 100%;
        min-width: 190px;
        text-align: center;
        outline: none;

        input {
            padding: 0;
        }
    }

    &.size_small {
        padding: 4px;
        gap: 4px;

        & > button {
            cursor: pointer;
            padding: 6px;
        }

        & > div {
            min-width: 60px;
        }
    }
}

// .input {
//     --pure-material-safari-helper1: rgb(
//         var(--pure-material-primary-rgb, 33, 150, 243)
//     );
//     position: relative;
//     display: inline-block;
//     padding-top: 6px;
//     font-family: var(
//         --pure-material-font,
//         "Roboto",
//         "Segoe UI",
//         BlinkMacSystemFont,
//         system-ui,
//         -apple-system
//     );
//     font-size: 16px;
//     line-height: 1.5;
//     overflow: hidden;
//     pointer-events: all;

//     > input,
//     > textarea {
//         box-sizing: border-box;
//         margin: 0;
//         border: solid 1px; // Safari
//         border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
//         border-top-color: transparent;
//         border-radius: 4px;
//         padding: 15px 13px 15px;
//         width: 100%;
//         height: inherit;
//         color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
//         background-color: transparent;
//         box-shadow: none; // Firefox
//         font-family: inherit;
//         font-size: inherit;
//         line-height: inherit;
//         caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
//         transition:
//             border 0.2s,
//             box-shadow 0.2s;
//     }

//     > input + span,
//     > textarea + span {
//         position: absolute;
//         top: 0;
//         left: 0;
//         display: flex;
//         border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
//         width: 100%;
//         max-height: 100%;
//         color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
//         font-size: 75%;
//         line-height: 15px;
//         cursor: text;
//         transition:
//             color 0.2s,
//             font-size 0.2s,
//             line-height 0.2s;

//         &::before,
//         &::after {
//             content: "";
//             display: block;
//             box-sizing: border-box;
//             margin-top: 6px;
//             border-top: solid 1px;
//             border-top-color: rgba(
//                 var(--pure-material-onsurface-rgb, 0, 0, 0),
//                 0.6
//             );
//             min-width: 10px;
//             height: 8px;
//             pointer-events: none;
//             box-shadow: inset 0 1px transparent;
//             transition:
//                 border-color 0.2s,
//                 box-shadow 0.2s;
//         }

//         &::before {
//             margin-right: 4px;
//             border-left: solid 1px transparent;
//             border-radius: 4px 0;
//         }

//         &::after {
//             flex-grow: 1;
//             margin-left: 4px;
//             border-right: solid 1px transparent;
//             border-radius: 0 4px;
//         }
//     }

//     &:hover {
//         > input,
//         > textarea {
//             border-color: rgba(
//                 var(--pure-material-onsurface-rgb, 0, 0, 0),
//                 0.87
//             );
//             border-top-color: transparent;
//         }

//         > input + span::before,
//         > textarea + span::before,
//         > input + span::after,
//         > textarea + span::after {
//             border-top-color: rgba(
//                 var(--pure-material-onsurface-rgb, 0, 0, 0),
//                 0.87
//             );
//         }

//         > input:not(:focus):placeholder-shown,
//         > textarea:not(:focus):placeholder-shown {
//             border-color: rgba(
//                 var(--pure-material-onsurface-rgb, 0, 0, 0),
//                 0.87
//             );
//         }
//     }

//     > input:not(:focus):placeholder-shown,
//     > textarea:not(:focus):placeholder-shown {
//         border-top-color: rgba(
//             var(--pure-material-onsurface-rgb, 0, 0, 0),
//             0.6
//         );
//     }

//     > input:not(:focus):placeholder-shown + span,
//     > textarea:not(:focus):placeholder-shown + span {
//         font-size: inherit;
//         line-height: 68px;
//         border-color: red;

//         &::before,
//         &::after {
//             border-top-color: transparent;
//         }
//     }

//     > input:focus,
//     > textarea:focus {
//         border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
//         border-top-color: transparent;
//         box-shadow:
//             inset 1px 0 var(--pure-material-safari-helper1),
//             inset -1px 0 var(--pure-material-safari-helper1),
//             inset 0 -1px var(--pure-material-safari-helper1);
//         outline: none;

//         + span {
//             color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));

//             &::before,
//             &::after {
//                 border-top-color: var(
//                     --pure-material-safari-helper1
//                 ) !important;
//                 box-shadow: inset 0 1px var(--pure-material-safari-helper1);
//             }
//         }
//     }

//     > input:disabled,
//     > input:disabled + span,
//     > textarea:disabled,
//     > textarea:disabled + span {
//         border-color: rgba(
//             var(--pure-material-onsurface-rgb, 0, 0, 0),
//             0.38
//         ) !important;
//         border-top-color: transparent !important;
//         color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
//         pointer-events: none;

//         &::before,
//         &::after {
//             border-top-color: rgba(
//                 var(--pure-material-onsurface-rgb, 0, 0, 0),
//                 0.38
//             ) !important;
//         }
//     }

//     > input:disabled:placeholder-shown,
//     > input:disabled:placeholder-shown + span,
//     > textarea:disabled:placeholder-shown,
//     > textarea:disabled:placeholder-shown + span {
//         border-top-color: rgba(
//             var(--pure-material-onsurface-rgb, 0, 0, 0),
//             0.38
//         ) !important;

//         &::before,
//         &::after {
//             border-top-color: transparent !important;
//         }
//     }

//     // Faster transition in Safari for less noticeable fractional font-size issue
//     @media not all and (min-resolution: 0.001dpcm) {
//         @supports (-webkit-appearance: none) {
//             > input,
//             > input + span,
//             > textarea,
//             > textarea + span,
//             > input + span::before,
//             > input + span::after,
//             > textarea + span::before,
//             > textarea + span::after {
//                 transition-duration: 0.1s;
//             }
//         }
//     }
// }
