@import "@packages/styles/variables";

.ScrollTop {
    z-index: 10;
    position: fixed;
    bottom: 65px;
    right: 65px;
    padding: 15px 16px;
    border-radius: $radius-m;
    border: 1px solid var(--primary-accent);
    opacity: 0;
    transition:
        opacity 0.5s ease,
        transform 1s ease,
        background-color 0.3s;

    .arrow {
        padding: 8px;
        background: var(--primary-accent);
        border-radius: 50%;
        aspect-ratio: 1/1;
    }

    &:active {
        transform: scale(0.9) !important;
    }

    &.visible {
        animation: fadeIn 0.2s ease-in-out forwards;
    }

    &.hidden {
        animation: fadeOut 0.2s ease-in-out forwards;
    }

    &:hover {
        background: var(--card);
        color: var(--primary);

        .arrow {
            background: var(--background);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(100px);
    }
}
