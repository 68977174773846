@import "@packages/styles/palette";
@import "@packages/styles/mixins";

.header {
    opacity: 1;
}

// .fixed_container {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     z-index: 5;
//     position: fixed;
//     transition: all 10s forwards;
//     top: 0;

//     &[data-state="static"] {
//         transform: translateY(100%);
//     }

//     &[data-state="fixed"] {
//         transform: translateY(0);
//         box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//     }
// }

.fixed_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: fixed;
    transition: top 0.5s ease;
    top: -100%;
}

.fixed_container[data-state="static"] {
    top: -150px;
    opacity: 0.3;
}
.fixed_container[data-state="fixed"] {
    opacity: 1;
    top: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.top_header_wrapper {
    background: $deep-black;
}

.top_header {
    max-height: 75px;
    @include heading-5();
    color: $white;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    gap: 70px;
    .menu {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 40px;
        }
    }

    .phone {
        font-size: 20px;
    }
}

.base_header_wrapper {
    background: var(--card);
    & > div {
        display: flex;
        gap: 24px;
        align-items: center;
    }
}

.compose {
    align-items: stretch;
    display: flex;
    width: 100%;
    gap: 8px;
}

.base_header {
    padding: 16px 0;
    color: var(--card-foreground);
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
    transition: all 2s linear;
    box-sizing: border-box;

    &_logo {
        display: flex;
        align-items: center;
    }

    & .navigation_root {
        @include text-input();
        position: relative;
        display: flex;
        justify-self: end;
        justify-self: end;
    }

    & .navigation_list {
        list-style: none;
        display: flex;
        gap: 24px;
        justify-content: center;
    }

    & .navigation_trigger {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    & .navigation_link {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;
    }

    & .navigation_viewport {
        position: relative;
        background-color: var(--card);
        border-radius: $radius-m;
        overflow: hidden;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
        transition:
            width,
            height,
            300ms ease;
    }

    & .navigation_icon {
        width: 24px;
        height: 24px;
    }

    & .viewport_position {
        position: absolute;
        display: flex;
        justify-content: start;
        top: 100%;
        left: 0;
        z-index: 5;
    }

    & .caret_down {
        position: relative;
        top: 1px;
        transition: transform 250ms ease;
    }
    &[data-state="open"] > .caret_down {
        transform: rotate(-180deg);
    }
}

.button {
    justify-self: start;
    box-sizing: border-box;
    width: 100%;
    max-width: 196px !important;
}

.bottom_header {
    @include text-footnote();
    width: 100%;
    background: var(--card);
    border-top: 1px solid #0000000d;

    &_navigation ul {
        display: flex;
    }

    &_navigation a {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 24px;
    }

    &_navigation a:hover {
        cursor: pointer;
        background: var(--secondary);
        outline: 1px solid var(--secondary-accent);
    }
}

@keyframes shrink {
    from {
        position: static;
        opacity: 1;
        width: 135px;
    }
    to {
        opacity: 0;
        width: 0;
        position: absolute;
    }
}

@keyframes grow {
    from {
        position: absolute;
        opacity: 0;
        width: 0;
    }
    to {
        position: static;
        opacity: 1;
        width: 135px;
        display: block;
    }
}
