@import "@packages/styles/variables";
@import "@packages/styles/mixins";
@import "@packages/styles/palette";

.badge {
    display: flex;
    align-items: center;
    @include text-badge();
    padding: 4px 8px;
    border-radius: $radius-xs;
    height: max-content;

    &_variant_deep_blue {
        background: $deep-blue;
        color: $white;
    }

    &_variant_light_blue {
        background: $light-blue;
        color: $dark-gray;
    }

    &_variant_gold {
        background: $dark-gold;
        color: $white;
    }
}
