@import "@packages/styles/variables";
@import "@packages/styles/mixins";

.SelectTrigger {
    @include text-footnote();
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    align-items: center;
    border-radius: $radius-s;
    padding: 16px;
    gap: 6px;
    box-sizing: border-box;

    &_theme_outline {
        border: 1px solid var(--field-border);
        justify-content: space-between;
        background: var(--card);
    }

    &_theme_ghost {
        min-width: 8rem;
        width: max-content;
        color: var(--foreground-accent);
    }

    &_theme_ghost[data-state="open"] {
        background: var(--card);
    }
    &_theme_ghost + div div {
        border: none;
    }
}

.SelectContent {
    @include text-footnote();
    padding: 8px;
    width: var(--radix-select-trigger-width);
    min-width: 8rem;
    overflow: hidden;
    background-color: var(--card);
    border-radius: $radius-s;
    border: 1px solid var(--field-border);
    z-index: 5;
    overflow-y: auto;
    max-height: 300px;
}

.SelectContent[data-state="open"][data-side="bottom"] {
    padding-top: 16px;
    margin-top: -16px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
}

.SelectContent[data-state="open"][data-side="top"] {
    padding-bottom: 16px;
    margin-bottom: -16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.SelectViewport {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SelectItem {
    cursor: pointer;
    outline: none;
    border-radius: $radius-xs;
    display: flex;
    position: relative;
    user-select: none;
    padding: 4px 8px;
}

.SelectItem:hover {
    background: var(--secondary);
}

[data-state="open"] > .carret {
    transition: transform 0.2s ease-in;
    transform: rotate(180deg);
}
