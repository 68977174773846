@import "@packages/styles/variables";

.slider_input {
    position: relative;
    border: 1px solid var(--field-border);
    border-bottom: none;
    border-radius: $radius-m;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:focus-within {
        border-color: var(--field-accent);
    }

    .input {
        outline: none;
    }

    .slider {
        position: absolute;
        width: 100%;
        bottom: -10px;
    }
}
