@import "@packages/styles/mixins";

.footer {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    p {
        @include text-input();
    }

    .navigation {
        display: flex;
        gap: 48px;

        & > div:first-child {
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            a {
                @include heading-2();
            }
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .navigation_list {
        color: var(--foreground-accent);
        margin-top: 15px;
        padding: 0 24px;

        h2 {
            @include heading-2();
            color: var(--foreground);
        }

        ul {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .disclaimer {
        background: var(--card);
        padding: 16px 24px;
        border-radius: $radius-s;
    }

    .socials {
        margin-top: 4px;
        color: var(--primary);
        display: flex;
        align-items: center;
        gap: 16px;
    }
}
