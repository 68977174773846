.placeholder {
    width: 100%;
    aspect-ratio: 1/1;
    max-height: 100%;
    display: flex;

    & img {
        width: 100%;
    }
}
