@import "@packages/styles/mixins";
@import "@packages/styles/variables";

.disclaimer {
    @include text-footnote();
    border-radius: $radius-m;
    opacity: 1;

    &_variant_muted {
        background: var(--background);
    }
}

.icon {
    margin-top: 4px;
    min-width: 16px;
    min-height: 16px;
    color: var(--foreground-accent);
}

.content {
    display: flex;
    gap: 16px;
    align-items: start;
}
