@import "@packages/styles/variables";

.card {
    background: var(--card);
    color: var(--card-foreground);
    padding: 24px;
    border-radius: $radius-l;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
