.prompt {
    .description {
        text-align: center;
    }
    .footer {
        margin-top: 16px;
        display: flex;
        gap: 16px;
    }

    button {
        width: 100%;
    }
}
