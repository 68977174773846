.dark-theme {
    --background: #{$dark-bg}; // Очень темный фон
    --foreground: #{$deep-white}; // Светлый серо-белый текст

    --accent: #{$teal}; // Яркий бирюзовый для акцентов
    --primary: #{$purple}; // Яркий фиолетовый для основных элементов
    --primary-foreground: #{$white}; // Белый текст на основном фоне
    --primary-accent: #{$deep-purple}; // Темный фиолетовый для акцентов

    --secondary: #{$orange}; // Яркий оранжевый для второстепенных акцентов
    --secondary-foreground: #{$deep-white}; // Светлый серо-белый текст на второстепенном фоне
    --secondary-accent: #{$dark-gold}; // Темный золотисто-коричневый для акцентов

    --card: #{$dark-card}; // Темно-серый фон для карточек
    --card-foreground: #{$white}; // Белый текст на карточках

    --muted: #{$dark-muted}; // Очень темно-серый для muted элементов
    --muted-foreground: #{$gray}; // Серый текст для muted элементов

    --field: #{$dark-card}; // Поля ввода, такой же цвет как у карточек
    --field-foreground: #{$deep-white}; // Светлый текст на полях ввода
    --field-border: #{$deep-teal}; // Темный бирюзовый для границ полей

    --promo: #{$pink}; // Яркий розовый для промо-элементов
    --promo-foreground: #{$deep-black}; // Черный текст на промо-элементах

    --error-foreground: #{$pink}; // Розово-красный для ошибок

    --slider: #{$teal}; // Яркий бирюзовый для ползунков
    --slider-foreground: #{$deep-teal}; // Темный бирюзовый текст или элементы на ползунке
}
